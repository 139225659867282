import $ from 'jquery';
import moment from 'moment';
import countdown from 'countdown';

export class ChargesPage {
  static setup() {
    ChargesPage.updateTimer();
    setInterval(function() {
      ChargesPage.updateTimer();
    }, 1000);

    $('.bootstrap_switch').change(function() {
      ChargesPage.on_switch_change($(this));
    });

    $('.check_all_table').click(function() {
      ChargesPage.select_all_table_column_checkboxes($(this), true);
    });

    $('.check_none_table').click(function() {
      ChargesPage.select_all_table_column_checkboxes($(this), false);
    });

    $('.check_all_form').click(function() {
      ChargesPage.select_all_form_checkboxes($(this), true);
    });

    $('.check_none_form').click(function() {
      ChargesPage.select_all_form_checkboxes($(this), false);
    });

    if ($('#time_entry_charge_actual_end_time').length) {

      const start = $('#time_entry_charge_actual_start_time.linked');
      if (start.length) {
        const end = $('#time_entry_charge_actual_end_time');
        this.link_pickers(start, end);
      }
    }

    ChargesPage.setupAdjustTimes();
  }

  static updateTimer() {
    let timer = $('#timer');
    countdown( new Date(timer.data('chargeStarted')), function(ts) {
      $('.countdown-amount.hours').text(ts.hours)
      $('.countdown-amount.minutes').text(ts.minutes)
      $('.countdown-amount.seconds').text(ts.seconds)
    } );
  }

  static setupAdjustTimes() {
    const originalAdjustedStartTime = $('#adjusted-start-datetime').val();
    const originalAdjustedEndTime = $('#adjusted-end-datetime').val();

    $('.form-inputs').one('click', '#adjusted-start-datetime', function(_event) {
      const target = $(this);
      const actualStartDatetime = $('#actual-start-datetime').val();
      if (!originalAdjustedStartTime) {
        target.val(actualStartDatetime);
      }
    });

    $('.form-inputs').one('click', '#adjusted-end-datetime', function(_event) {
      const target = $(this);
      const actualEndDatetime = $('#actual-end-datetime').val();
      if (!originalAdjustedEndTime) {
        target.val(actualEndDatetime);
      }
    });
  }

  static link_pickers(start, end) {
    const end_dtp = end.data('DateTimePicker');
    const start_dtp = start.data('DateTimePicker');

    const old_end_date = end_dtp.date();
    end.on('dp.change', _e => {
      end.trigger('change');
      return ChargesPage.set_datetime(old_end_date, end_dtp);
    });
    const old_start_date = start_dtp.date();
    start.on('dp.change', _e => {
      start.trigger('change');
      return ChargesPage.set_datetime(old_start_date, start_dtp);
    });
    return start.blur(() => {
      const date = start_dtp.date();
      if (date) {
        const now = moment();
        if (date.isSame(now, 'day')) {
          return end_dtp.defaultDate(now);
        }
        return end_dtp.defaultDate(start_dtp.date());

      }
      return null;
    });
  }

  static set_datetime(old_date, element) {
    const input_date = moment(element.date()._i).format('M/DD/YYYY');
    const input_time = moment(element.date()._i).format('h:mm');
    const old_time = moment(old_date._i).format('h:mm:ss A');
    if (this.input_time_is_now(input_time)) {
      return element.date(moment(`${input_date} ${old_time}`));
    }

    return null;
  }

  static input_time_is_now(input_time) {
    return input_time === moment().format('h:mm');
  }

  static select_all_table_column_checkboxes(element, checked) {
    const parent_table = element.closest('table');
    const index = parent_table.find('th').index(element.closest('th')) + 1;
    return parent_table.find(`tr td:nth-child(${index})`).each(function() {
      const form_switch = $(this).find('.bootstrap_switch');
      if (!form_switch.prop('disabled')) {
        form_switch.prop('checked', checked);
        return form_switch.trigger('change');
      }
      return null;
    });
  }

  static select_all_form_checkboxes(element, checked) {
    const parent_form = element.closest('form');
    return parent_form.find("input[type='checkbox']").each(function() {
      const checkbox = $(this);
      if (!checkbox.prop('disabled')) {
        checkbox.prop('checked', checked);
        return checkbox.trigger('change');
      }
      return null;
    });
  }

  static on_switch_change(form_switch) {
    const charge = {};
    charge[form_switch.data('update-field')] = form_switch.prop('checked');
    return $.ajax({
      type: 'PUT',
      dataType: 'json',
      url: form_switch.data('update-state-url'),
      data: {
        charge
      },
      success(data, _status, _jqXHR) {
        form_switch.closest('td').find('.col-error').remove();
        if (data && data.errors) {
          form_switch.closest('td').prepend(`<div class='col-error' data-toggle='tooltip' data-placement='top' title='${data.errors[0]}'>` + 'Error ' + '<i class=\'fa fa-times-circle\'></i>' + '</div>');
          $('[data-toggle="tooltip"]').tooltip();
        }
      }
    });
  }

}
